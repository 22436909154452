import React from 'react';
import { Section } from '../components/Section/Section';
import Layout from '../layouts';

const NotFoundPage = () => (
	<Layout>
		<div className="main">
			<Section>
				<div className="mw7 center ph3-ns pv5 pv0-l">
					<div className="cf ph4 ph2-ns tj">
						<h1>Privacy Policy</h1>
						I cookie sono piccoli file di testo che i siti visitati dagli utenti inviano ai loro terminali,
						dove vengono memorizzati per essere ritrasmessi agli stessi siti in occasione di visite successive.
						I cookie sono utilizzati per diverse finalità, hanno caratteristiche diverse e possono essere
						utilizzati sia dal titolare del sito che si sta visitando, sia da terze parti.
						Di seguito troverai tutte le informazioni sui cookie installati attraverso questo sito
						e le indicazioni necessarie su come gestire le tue preferenze riguardo ad essi.

						<h2>Il Titolare del Trattamento</h2>
						L’utilizzo di cookie da parte di <b>Giovedì Pizza</b>, senza sede, titolare di questo sito,
						si inquadra nella Privacy Policy dello stesso; per tutte le informazioni richieste
						dall’art. 13 Codice della Privacy.
						Per ulteriori informazioni o reclami puoi scriverci.

						<h2>Utilizzo dei Cookie</h2>
						Il nostro sito potrebbe utilizzare cookie per diversi motivi, tra cui:

						<ul>
							<li>
								<b>Memorizzare preferenze e impostazioni dell’utente.</b> Se un utente inserisce i suoi dati per
								interagire con i servizi del sito, questo potrebbe memorizzare le informazioni in modo che l’utente
								possa essere già profilato quando ritornerà sul sito. Questo permette di risparmiare tempo, eliminando
								la necessità di inserire ripetutamente le stesse informazioni ogni volta che si visita il sito.
							</li>
							<li>
								<b>Pubblicità mirata.</b> Quando vengono mostrati messaggi pubblicitari online, sul dispositivo
								dell’utente rimangono uno o più cookie permanenti che riconoscono il dispositivo ogni volta
								che viene visualizzato un messaggio pubblicitario in modo da registrare le preferenze
								dell’utente e mostrare contenuti pubblicitari coerenti con i suoi interessi.
							</li>
							<li>
								<b>Analisi dei siti.</b> Usiamo i cookie per contare il numero di visitatori unici di una pagina
								Web o di un servizio o per sviluppare altre statistiche aggregate relative al funzionamento dei
								siti e dei servizi. Queste analisi favoriscono il funzionamento e il miglioramento delle prestazioni
								di questi siti e servizi.
							</li>
						</ul>

						<h2>Cookie Tecnici che non richiedono il consenso</h2>

						<ul>
							<li>
								<b>A) Cookie relativi ad attività strettamente necessarie al funzionamento del sito e all’erogazione
									del servizio:</b>sono cookie volti a garantire la normale navigazione del sito.
								Nella maggior parte dei casi si tratta di cookie di sessione.
							</li>
							<li>
								<b>B) Cookie relativi ad attività di salvataggio delle preferenze e ottimizzazione:</b> 
								sono cookie che permettono all’utente di navigare sul sito in funzione dei criteri selezionati
								(come ad esempio la lingua) al fine di consentire la fruizione dei servizi resi e/o di migliorarli.
								Nella maggior parte dei casi si tratta di cookie che hanno una durata massima di 731 giorni.
								Tutti i cookie tecnici non richiedono consenso, perciò vengono installati automaticamente a
								seguito dell’accesso al sito e non vengono utilizzati per scopi ulteriori.
							</li>
						</ul>

						<h2>Cookie per i quali è richiesto il consenso</h2>
						Tutti i cookie diversi da quelli tecnici sopra indicati vengono installati o attivati solo a
						seguito del <b>consenso espresso</b>dall’utente la prima volta che visita il sito.
						Il consenso può essere espresso in maniera generale, interagendo con il banner di informativa
						breve presente sulla pagina di Approdo del sito, secondo le modalità indicate in tale banner
						(chiudendo il banner, o cliccando sul tasto OK, o scorrendo la pagina o cliccando su qualunque
						suo elemento); oppure può essere fornito in maniera selettiva, secondo le modalità di seguito
						indicate. Di questo consenso viene tenuta traccia in occasione delle visite successive.
						Tuttavia, <b>l’utente ha sempre la possibilità di revocare in tutto o in parte il
						consenso già espresso.</b>

						<h3>Cookie gestiti da terze parti</h3>
						Attraverso questo sito vengono installati anche cookie gestiti da terze parti. Per tutti
						potrai esprimere il tuo consenso anche chiudendo questa pagina o passando a un’altra pagina del sito.

						<h4>Cookie di Profilazione Pubblicitaria</h4>

						<h5>Google Analytics:</h5>
						Questo Sito/App utilizza Google Analytics, un servizio di analisi web fornito da Google, Inc.
						("Google"). Google Analytics utilizza cookie", ossia file di testo che vengono memorizzati
						sul computer dell’utente per consentire di analizzare come gli utenti utilizzano il Sito/App.
						Le informazioni generate dai cookie sull'utilizzo del Sito/App da parte dell’Utente verranno
						trasmesse a un server di Google dove saranno memorizzate. Google utilizzerà queste informazioni
						per analizzare l'utilizzo del Sito/App da parte dell’utente, redigere rApporti sulle attività
						sul Sito/App e per fornire ulteriori servizi al gestore del presente Sito/App correlati
						all'utilizzo del Sito/App e all'utilizzo di Internet. Google non assocerà l'indirizzo IP
						trasmesso dal browser dell’utente nell'ambito di Google Analytics ad altri dati in possesso
						di Google. L’utente può impedire la memorizzazione dei cookie modificando le impostazioni
						del software del proprio browser ma ciò potrebbe rendere le funzioni del Sito/App meno
						personalizzate sulla base delle preferenze dell’utente stesso. L’utente può ricevere
						informazioni sulle attività di trattamento dei suoi dati personali poste in essere da Google
						Analytics al seguente indirizzo: https://support.google.com/analytics/answer/6004245; egli
						inoltre può impedire la registrazione da parte di Google dei dati prodotti dai cookie e
						relativi al suo utilizzo del Sito/App (incluso il suo indirizzo IP) nonché l'elaborazione
						di tali dati da parte di Google, scaricando e installando il plugin del browser disponibile
						al seguente link: http://tools.google.com/dlpage/gaoptout?hl=it. Utilizzando il Sito/App,
						l’utente acconsente al trattamento dei propri dati da parte di Google con le modalità e
						per i fini indicati nella privacy policy di Google in relazione all’utilizzo di Google
						Analytics, accessibile al seguente link: https://www.google.com/intl/it/policies/privacy/partners/

						<h4>Cookies di Retargeting</h4>
						Vengono utilizzati per l’invio di pubblicità a soggetti che hanno precedentemente visitato questo sito.
						Troverai di seguito il nome dei terzi che li gestiscono, e per ciascuno di essi il link alla pagina
						nella quale potrai ricevere le informazioni sul trattamento e esprimere il tuo consenso.

						<h4>Facoltatività del Conferimento dei Dati</h4>
						A parte quanto specificato per i dati di navigazione, l’utente è libero di fornire i dati
						personali riportati nei moduli di richiesta informazioni o comunque indicati in contatti
						per l’invio di materiale informativo o di altre comunicazioni. Il loro mancato conferimento
						può esercitato con la procedura di invio elettronico delle informazioni può comportare
						l’impossibilità di ottenere quanto richiesto.

						<h4>Modalità del Trattamento</h4>
						I dati personali sono trattati con strumenti automatizzati per il tempo strettamente necessario
						a conseguire gli scopi per cui sono stati raccolti. Specifiche misure di sicurezza sono osservate
						per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.

						<h4>Diritti degli interessati</h4>
						I soggetti cui si riferiscono i dati personali hanno il diritto in qualunque momento di ottenere
						la conferma dell’esistenza o meno dei medesimi dati e di conoscerne il contenuto e l’origine,
						verificarne l’esattezza o chiederne l’integrazione o l’aggiornamento, oppure la rettificazione
						(art. 7 del d.lgs. n. 196/2003). L’interessato a quindi il diritto di chiedere la cancellazione,
						la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, nonché
						di opporsi in ogni caso, per motivi legittimi, al loro trattamento. Le richieste vanno rivolte
						al titolare del trattamento.

						<h3>Come posso controllare l'installazione dei cookies?</h3>
						Se non conosci il tipo e la versione di browser che stai utilizzando, clicca su “Aiuto” nella
						finestra del browser in alto, da cui puoi accedere a tutte le informazioni necessarie.
						Se invece conosci il tuo browser clicca su quello che stai utilizzando per accedere alla
						pagina di gestione dei cookie.

						<ul>
							<li><b>Internet Explorer:</b> <a href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11">http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11</a></li>
							<li><b>Google Chrome:</b> <a href="https://support.google.com/accounts/answer/32050?hl=it">https://support.google.com/accounts/answer/32050?hl=it</a></li>
							<li><b>Mozilla Firefox:</b> <a href="https://support.mozilla.org/it/kb/Eliminare%20i%20cookie">https://support.mozilla.org/it/kb/Eliminare%20i%20cookie</a></li>
							<li><b>Safari</b> <a href="https://support.Apple.com/it-it/HT201265">https://support.Apple.com/it-it/HT201265</a></li>
						</ul>
					</div>
				</div>
			</Section>
		</div>
	</Layout>
);

export default NotFoundPage;
